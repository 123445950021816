<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: null,
      validator: (value) =>
        ['mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive'].includes(
          value
        ) || value === null,
    },
    relaxed: {
      type: Boolean,
      default: false,
    },
    veryRelaxed: {
      type: Boolean,
      default: false,
    },
    divided: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    middleAligned: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        list: true,
        relaxed: this.relaxed,
        'very relaxed': this.veryRelaxed,
        'middle aligned': this.middleAligned,
        divided: this.divided,
        animated: this.animated,
        selection: this.selection,
        link: this.link,
        horizontal: this.horizontal,
        ...(this.size ? { [this.size]: true } : {}),
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ui.list {
  margin-top: 0;
}
</style>
