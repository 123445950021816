<template>
  <router-link v-if="to" :to="to" :class="classes">
    <img v-if="src" class="ui avatar image" :src="src" />
    <i v-if="icon" :class="icon" />
    <slot />
  </router-link>
  <div v-else :class="classes">
    <img v-if="src" class="ui avatar image" :src="src" />
    <i v-if="icon" :class="icon" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    to: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        item: true,
        disabled: this.disabled,
      }
    },
  },
}
</script>
