<template>
  <div :class="[{ 'vertically-aligned-image': verticallyAligned && src }]">
    <g-image
      v-if="src"
      :src="src"
      :size="size"
      alt="placeholder"
      :class="['pointer', { centered }]"
    />
    <span :class="classes"><slot /></span>
  </div>
</template>

<script>
import gImage from '@/components/v2/image.vue'

export default {
  components: { gImage },
  props: {
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large', 'big', 'huge', 'massive'].includes(
          value
        )
      },
    },
    src: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    verticallyAligned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        text: true,
        error: this.error,
        warning: this.warning,
        grey: this.light,
        centered: this.centered,
        'vertically-aligned': this.verticallyAligned && !this.src,
        [this.size]: true,
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';

.ui.text {
  margin-top: 1em;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertically-aligned {
  height: 10em;
}

.vertically-aligned-image {
  margin-top: 10em;
}
</style>
