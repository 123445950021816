<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    floatRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        content: true,
        'right floated': this.floatRight,
      }
    },
  },
}
</script>
