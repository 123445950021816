<template>
  <div
    v-tippy
    :content="tooltip"
    :class="classes"
    @show="() => !!tooltip && !!icon"
  >
    <input type="hidden" :name="name" />
    <i v-if="icon" :class="[icon, 'icon']" />
    <div v-if="defaultText" :class="['text', { default: selection }]">
      {{ defaultText }}
    </div>
    <i v-if="!hideCaret" class="dropdown icon" />
    <div class="menu">
      <slot />
    </div>
  </div>
</template>

<script>
import jquery from 'jquery'

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
    buttonVariant: {
      type: String,
      default: null,
      validator: (value) =>
        [
          null,
          'primary',
          'secondary',
          'positive',
          'negative',
          'default',
        ].includes(value),
    },
    hideCaret: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    defaultText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    hideSelection: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    initialValue: {
      type: [String, Number, Object, Boolean],
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    labeled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    scrolling: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        dropdown: true,
        active: this.active,
        basic: this.basic,
        button: this.button,
        ...(this.buttonVariant ? { [this.buttonVariant]: true } : {}),
        circular: this.circular,
        clearable: this.clearable,
        compact: this.compact,
        disabled: this.disabled,
        error: this.error,
        floating: this.floating,
        fluid: this.fluid,
        icon: !!this.icon,
        inline: this.inline,
        labeled: this.labeled,
        loading: this.loading,
        scrolling: this.scrolling,
        selection: this.selection,
        search: this.search,
        [this.size]: true,
      }
    },
  },
  watch: {
    hideSelection: {
      handler() {
        this.init()
      },
    },
    initialValue: {
      handler() {
        this.setSelected()
      },
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  methods: {
    init() {
      jquery(this.$el).dropdown({
        fullTextSearch: 'exact',
        ignoreDiacritics: true,
        forceSelection: false,
        action: this.hideSelection ? 'select' : 'activate',
        allowReselection: this.hideSelection,
        onChange: (value) => {
          const updateValue = value.slice(1)
          // don't emit if values match
          if (JSON.stringify(this.initialValue) === updateValue) {
            return
          }

          this.$emit('update', JSON.parse(updateValue || null))
        },
        onSearch: (value) => {
          this.$emit('search', value)
        },
      })

      this.setSelected()
    },
    setSelected() {
      if (this.initialValue !== null) {
        jquery(this.$el).dropdown(
          'set selected',
          `§${JSON.stringify(this.initialValue)}`
        )
      }
      if (this.initialValue === '') {
        this.clear()
      }
    },
    clear() {
      jquery(this.$el).dropdown('clear')
    },
    show() {
      jquery(this.$el).dropdown('show')
    },
    hide() {
      jquery(this.$el).dropdown('hide')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.ui.dropdown:not(.selection):not(.search):not(.button):hover {
  background: @light-grey-hover;
  border-radius: 1px !important;
  box-shadow: 0 0 0 0.5em @light-grey-hover;
}

.ui.button.icon.dropdown {
  &.primary {
    color: @blue;
    background: none;
  }

  &.secondary {
    color: @dark-grey;
    background: none;
  }
}

.ui.button.icon.dropdown:hover {
  &.primary {
    background: @light-grey-hover;
  }

  &.secondary {
    background: @light-grey-hover;
  }
}
</style>
