<template>
  <!--
    fomantic is internally converting JSON string values into [object Object]
    so by prepending a character that is not JSON, we can avoid that bad behavior
  -->
  <div :class="classes" :data-value="`§${JSON.stringify(value)}`">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        item: true,
        disabled: this.disabled,
        vertical: this.vertical,
      }
    },
  },
}
</script>
