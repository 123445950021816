<template>
  <flex align="center">
    <span
      ref="editableSpan"
      v-tippy
      :contenteditable="!disabled"
      type="text"
      :class="classes"
      :content="tooltip"
      role="textbox"
      :placeholder="placeholder"
      @show="() => !disabled && !!tooltip"
      @blur="onBlur"
      @keydown.enter="onEnter"
      v-text="value"
    />

    <loading :loading="loading" inline size="mini" />
  </flex>
</template>

<script>
import loading from '@/components/v2/loading.vue'
import flex from '@/components/v2/flex.vue'

export default {
  components: { loading, flex },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Add Text',
    },
    tooltip: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      return {
        'editable-text': true,
        disabled: this.disabled,
      }
    },
  },
  methods: {
    async onEnter(event) {
      event.preventDefault() // prevent newline insertion
      this.$refs.editableSpan.blur()
    },
    async onBlur() {
      if (this.$refs.editableSpan.innerText !== this.value) {
        this.$emit(
          'update',
          (this.$refs.editableSpan.innerText || '').trim() || null
        )
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

[contentEditable='true']:empty:not(:focus)::before {
  font-style: italic;
  color: @grey;
  content: attr(placeholder);
}

.editable-text {
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  border-radius: @standard-border-radius;
}

.editable-text:hover {
  cursor: text;
  background-color: @light-grey-hover;

  &.disabled {
    cursor: default;
    background-color: inherit;
  }
}

.editable-text:focus {
  min-width: 5em;
  cursor: text;
  background-color: @white;
  outline-color: @focus-ring-color;
}
</style>
